import React from 'react';
import PropTypes from 'prop-types';


const Main = ({
    classes,
    children
}) => {

    return (
        <>
            <main>
                {children}
            </main>    
        </>
  )
}

Main.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node
}

export default Main
