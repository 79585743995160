import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './style';
import {
    withStyles,
    Button,
    CircularProgress
} from '@material-ui/core';


const SubmitButton = ({
    styles,
    classes,
    children,
    isSubmitting,
    onClick,
    ...rest
}) => {

    const renderButtonContent = () => {
        if (isSubmitting) {
            return (
                <CircularProgress size={24} color={"#FFF"}/>
            )
        }
        return children

    }

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
        >
            {renderButtonContent()}
        </Button>
    )
};

SubmitButton.propTypes = {
    isSubmitting: PropTypes.bool,
    children: PropTypes.string,
    loadingColor: PropTypes.string
}

export default compose(
    withStyles(styles)
)(SubmitButton);