import React, { useEffect, useState, forwardRef, useImperativeHandle  } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDropzone } from 'react-dropzone';
import styles from './styles';
import {
    withStyles
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const FileDropzone = forwardRef (({
    classes,
    onUploadFile
}, ref ) => {


    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: 'image/*',
        noClick: true,
        maxFiles: 1,
        onDrop: acceptedFiles => {
            const files = acceptedFiles.map(file =>  Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            setFiles(previous => [...files])
            var reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = () => {
                const base64Image = Buffer.from(reader.result.replace(/^data:image\/\w+;base64,/, ""), 'base64')
                const preview = URL.createObjectURL(acceptedFiles[0])
                onUploadFile({ base64Image: base64Image, preview: preview })
            };
        }
    });

    useImperativeHandle(ref, () => ({
        browse() {
            open()
        }
    }));

  
    const removeFileHandler = (file, index) => {
        const filesCopy = [...files];
        const fls = filesCopy.filter(fl => fl.preview !== file.preview)
        setFiles(fls)
    }

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);



    return (
        <div className={classes.root}>
            <div className={classes.cardContentRoot}>
                <section className="container">
                    <div {...getRootProps({ className: classes.dropzone})}>
                        <input {...getInputProps()} />
                        <div className={classes.infoBox}>
                            {files.length > 0 ? 
                              <p>{files[0].name}</p>
                            : <p>To produce results, browse, or drag here, an image file to upload.</p>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
})

FileDropzone.propTypes = {
    classes: PropTypes.object,
    onUploadFile: PropTypes.func
}

export default compose(
    withStyles(styles)
)(FileDropzone)