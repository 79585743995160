
export default theme => ({
    root: {
        flexGrow: 1
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        position: 'relative',
        padding: '5px',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        margin: '0 8px 5px 5px',
        width: 150,
        height: 150,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        width: '100%',
        overflow: 'hidden',
        marginBottom: '5px',
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    closeBox: {
        cursor: 'pointer'
    },
    dropzone: {
        borderRadius: '10px',
        border: 'dashed 1px #969696',
        display: 'flex',
        outline: 'none',
        padding: '18px',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.8,
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    iconBox: {
        margin: '0 20px 10px',
        '& img': {
            width: '130px',
            objectFit: 'cover'
        }
    },
    infoBox: {
        '& p': {
            fontFamily: 'OpenSans',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 1.38,
            textAlign: 'center'
        }
    }
})

