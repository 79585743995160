import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    Container,
    Button,
    Divider
} from '@material-ui/core';
import logoIcon from './../../assets/icons/cycloid-logo.png'
import bulletIcon from './../../assets/icons/cycloid-bullets.png';
import * as api from '../../utils/api';
import FileDropzone from './../../components/FileDropzone';
import SubmitButton from './../../components/SubmitButton';

const LABELS = [
    { key: 'goucha', name: 'Manuel Luís Goucha' },
    { key: 'joao', name: 'João Felix'},
    { key: 'jose', name: 'Jose Alberto Carvalho' },
    { key: 'messi', name: 'Lionel Messi' },
    { key: 'moitra', name: 'Shantanu Moitra' }
]

const Home = ({
    classes
}) => {

    const [imageInfo, setImageInfo] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [matchIndex, setMatchIndex] = useState(-1)
    const [probabilies, setProbabilies] = useState([])
    const [showImage, setShowImage] = useState(false)
    const fileDropzoneRef = useRef();

    useEffect(() => {
        setShowImage(false)
        // TO DO 
        return () => {
            //
        }
    }, [])

    const onUploadFileHandler = async (data) => {
        setMatchIndex(-1)
        setShowImage(false)
        setImageInfo(data)
    }

    const uploadImage = async () => {
        setIsSubmitting(true)
        try {
            const postData = {
                base64Image: imageInfo.base64Image,
                endpointName: 'image-classification-2020-11-24-11-09-39-782',
                endpointRegion: 'eu-west-1'
            }
            const resp = await api.uploadImage(postData)
            const probs = resp.data.predictions
            const i = probs.indexOf(Math.max(...probs));
            setProbabilies(probs)
            setMatchIndex(i)
            setIsSubmitting(false)
            setShowImage(true)
        } catch (error) {
            setIsSubmitting(false)
            setMatchIndex(-1)
            setShowImage(true)

        }
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.header}>
                    <img src={logoIcon} alt="" />
                    <h1>Face recognition demo </h1>
                    <img src={bulletIcon} alt=""/>
                </div>
                <div className={classes.boxContent}>
                    <div className={classes.boxDropzone}>
                        <FileDropzone ref={fileDropzoneRef} onUploadFile={onUploadFileHandler}/>
                        <div className={classes.boxButton}>
                            <Button
                                style={{marginBottom: '8px'}}
                                variant="contained"
                                color="secondary"
                                onClick={() => fileDropzoneRef.current.browse()}>
                                Browse
                            </Button>
                            <SubmitButton
                                isSubmitting={isSubmitting}
                                onClick={uploadImage}>
                                Upload
                            </SubmitButton>
                        </div>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.boxImage}>
                            {(imageInfo && showImage) ? (<img src={imageInfo.preview} alt="" />) : <p>Your uploaded image</p>}
                        </div>
                        <div className={classes.boxInfo}>
                            <h3>Prediction:</h3>
                            <h5>
                                Based on the detected human face here's the predicted subject and the probability
                                score on the likelihood that the detected face is the face of a recognized subject.
                            </h5>
                            <Divider style={{ backgroundColor: '#969696'}}/>
                            <div className={classes.boxInfoResult}>
                                <div className={classes.boxInfoLabel}>
                                    <h4>Predicted Subject</h4>
                                    <h4>Probability</h4>
                                </div>
                                <div className={classes.boxInfoValue}>
                                    <h2>{matchIndex >= 0 ? LABELS[matchIndex].name : ''}</h2>
                                    <h2>{matchIndex >= 0 ? `${Number(probabilies[matchIndex]*100).toFixed(2)}%` : ''}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
       </div>
    )
}

Home.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Home)
