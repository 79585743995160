export default {
    label: {
        textTransform: 'none',
        fontFamily: 'OpenSans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1.38
    },
    contained: {
        boxShadow: 'none',
        padding: '9px',
        width: '200px',
        borderRadius: '10px'
    },
    containedPrimary: {
        backgroundColor: '#94070a',
        '&:hover': {
            backgroundColor: "#000000",
            '& .MuiButton-label': {
                color: '#FFFFFF'
            },
        }
    },
    containedSecondary: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #94070a',
        '& .MuiButton-label': {
            color: '#94070a'
        },
        '&:hover': {
            backgroundColor: "#000000",
            border: '1px solid #000000',
            '& .MuiButton-label': {
                color: '#FFFF'
            },
        }
    },

};
