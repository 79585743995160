
export default theme => ({
    root: {
        height: '100%',
        backgroundColor: 'white'
    },
    container: {
        paddingRight: '90px',
        paddingLeft: '139px'
    },
    header: {
        display: 'flex',
        width: '100%',
        margin: '9px 0 36px',
        '& h1': {
            marginLeft: 'auto'
        },
        '& img': {
            objectFit: 'contain'
        }
    },
    boxDropzone: {
        display: 'flex'
    },
    boxButton: {
        marginLeft: '15px',
        paddingRight: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    content: {
        display: 'flex',
        marginTop: '30px',
        alignItems: 'center',
        marginBottom: '30px'
    },
    boxImage: {
        width: '479px',
        height: '479px',
        backgroundColor: '#c8c8c8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '& p': {
            fontFamily: 'OpenSans',
            fontSize: '16px',
            lineHeight: 1.38,
            color: '#FFFFFF',
            position: 'absolute'
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    boxInfo: {
        marginLeft: '30px',
        maxWidth: '490px',
        '& h3': {
            fontFamily: 'OpenSans',
            fontSize: '24px',
            fontWeight: 'bold',
            lineHeight: 1.38,
            color: '#94070a',
            marginBottom: '4px'
        },
        '& h5': {
            fontFamily: 'OpenSans',
            fontSize: '14px',
            lineHeight: 1.36,
            color: '#000000',
            fontWeight: 'normal',
        }
    },
    boxInfoResult: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
    },
    boxInfoLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h4': {
            fontFamily: 'OpenSans',
            fontSize: '16px',
            lineHeight: 1.36,
            color: '#000000',
            margin: 0,
            fontWeight: 'normal'
        }
    },
    boxInfoValue: {
        display: 'flex',
        justifyContent: 'space-between',
        '& h2': {
            fontFamily: 'OpenSans',
            fontSize: '24px',
            lineHeight: 1.36,
            color: '#000000',
            margin: 0,
            fontWeight: 'bold'
        }
    }
})
